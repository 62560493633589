import axios from 'axios'

const api = axios.create({
  baseURL: '/tracer',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function apiSearchDTCs({ makeModelID, searchString }) {
  const params = new URLSearchParams({ makeModelID, searchString })

  return api.get(`/dtcs/search?${params.toString()}`).then((resp) => resp.data)
}

export function apiFetchDTCsForProblemLog(problem_log_id) {
  const params = new URLSearchParams({ problem_log_id })
  return api
    .get(`/dtcs/search_by_problem_log?${params.toString()}`)
    .then((resp) => resp.data)
}
