import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { navigateToIncidentMgmtPage } from '../IncidentMgmt/IncidentMgmt'
import { linkProblemLogToIssue } from '../api/issues'

export const IssueStatus = ({ fault }) => {
  const hasMoreCases = () => {
    // if any of the problems are missing an attached issue
    if (!!fault.problems) {
      return fault.problems.some((prob) => {
        return !prob.attachedIssue
      })
    }
    return false
  }

  const isReviewed = () => {
    return !!fault.attachedIssue && !hasMoreCases()
  }

  const hasRelatedIssues = () => {
    return fault.relatedIssues && fault.relatedIssues.length > 0
  }

  if (isReviewed()) {
    return <span className="issue-status">Reviewed</span>
  }
  if (fault.attachedIssue && hasMoreCases()) {
    return <span className="issue-status">More Cases</span>
  }
  if (hasRelatedIssues()) {
    return <span className="issue-status">Pending</span>
  }
  return <span className="issue-status">--</span>
}

export const IssueButton = ({ fault }) => {
  if (fault.attachedIssue) {
    return ''
  }
  let msg = 'Add Issue'
  if (fault.relatedIssues && fault.relatedIssues.length > 0) {
    msg = `${fault.relatedIssues.length} Issues`
  }
  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={() => {
        navigateToIncidentMgmtPage(fault)
      }}
    >
      {msg}
    </Button>
  )
}

export const AddCaseButton = ({ fault, issue, setFault, parentFault }) => {
  const attachFaultToIssue = () => {
    linkProblemLogToIssue(issue.id, fault.id).then(() => {
      const newParentFault = structuredClone(parentFault)
      newParentFault.problems.forEach((prob, idx) => {
        if (prob.id === fault.id) {
          newParentFault.problems[idx].attachedIssue = issue
        }
      })
      setFault(newParentFault)
    })
  }

  if (fault.attachedIssue || !issue) {
    return ''
  }

  return (
    <Button color="primary" variant="outlined" onClick={attachFaultToIssue}>
      Add Case
    </Button>
  )
}
