import { fetchIssues, fetchIssue, getIssueProblemLogs } from '../api/issues'
import { useQuery } from 'react-query'
import { useUserPlant } from './User'

const ISSUES_QUERY_KEY = 'issues'

export const useGetIssues = () => {
  const [userPlant] = useUserPlant()
  const {
    status,
    data: issues,
    error,
    isLoading,
  } = useQuery(
    [ISSUES_QUERY_KEY, userPlant],
    async () => {
      const result = await fetchIssues(userPlant)

      return result
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return { status, issues, error, isLoading }
}

export const useGetIssue = (issueId) => {
  const {
    status,
    data: issues,
    error,
    isLoading,
  } = useQuery(
    [ISSUES_QUERY_KEY, issueId],
    async () => {
      const result = await fetchIssue(issueId)

      return result
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return { status, issues, error, isLoading }
}

export const useGetIssueProblemLogs = (issueId) => {
  const {
    status,
    data: problemLogs,
    error,
    isLoading,
  } = useQuery(
    [ISSUES_QUERY_KEY, 'problem-logs', issueId],
    async () => {
      const result = await getIssueProblemLogs(issueId)

      return result
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return { status, problemLogs, error, isLoading }
}
