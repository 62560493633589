import { useQuery } from 'react-query'
import { apiGetFaults } from '../api/faults'
import { fetchAttachedIssue, fetchRelatedIssues } from '../api/issues'

export const FAULT_REPORT_QUERY_KEY = 'faults'

export const useFaults = ({
  makeModelIds,
  stationIds,
  start_at,
  end_at,
  station_alerts,
  plants,
  zones,
  teams,
  department,
  testZones,
}) => {
  const {
    status,
    data: faults,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [
      FAULT_REPORT_QUERY_KEY,
      makeModelIds,
      stationIds,
      start_at,
      end_at,
      station_alerts,
      plants,
      zones,
      teams,
      department,
      testZones,
    ],
    async () => {
      const result = await apiGetFaults({
        makeModelIds,
        stationIds,
        start_at,
        end_at,
        station_alerts,
        plants,
        zones,
        teams,
        department,
        testZones,
      })
      for (let idx = 0; idx < result.data.length; idx++) {
        const fault = result.data[idx]
        result.data[idx].attachedIssue = await fetchAttachedIssue(fault.id)
        let identifier = null
        if (fault.type === 'harness') {
          identifier = fault.harness
        } else if (fault.type === 'device') {
          identifier = fault.device_namne
        } else if (fault.type === 'component') {
          identifier = fault.component_id
        }
        if (identifier) {
          result.data[idx].relatedIssues = await fetchRelatedIssues(
            fault.id,
            fault.short_code,
            fault.component_id,
            fault.device_name || '',
            fault.harness || '',
            plants,
          )
        } else {
          result.data[idx].relatedIssues = []
        }
      }
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!start_at && !!end_at,
    },
  )
  return {
    status,
    faults,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
