// This is the right side of the figma
// https://www.figma.com/proto/ITEOBzG70wVkwRkep3rc0B/Tracer---Ant-Design?page-id=9316%3A23734&node-id=9613-21823&node-type=frame&viewport=-64%2C334%2C0.12&t=KPS4u8Suia3eGyRO-1&scaling=contain&content-scaling=fixed&starting-point-node-id=9607%3A15334
// If the issue and potentiallIssues are both null we list issues to select.
// If the issue is null but we have potentialIssues, we are here from an
//   "n issues" button and list them and allow linking the fault to the issue, as
//   well as an "Add Issue" button to create a new one.
// If the issue is not null we are adding (if the issue has no id) or editing
// All of the above is abstracted into the showList prop
import { useEffect, useState } from 'react'
import { IssueEditForm } from './IssueEditForm'
import { IssueList } from './IssueList'
import { fetchNewIssueIdForProblemLog } from '../api/issues'
import { useUserInfo } from '../hooks/User'
import { Input } from 'antd'

//   (if the issue has an ID), and we should list fault details here
export const IssueSummary = ({
  fault,
  issue,
  potentialIssues,
  setIssue,
  setFault,
  showList,
  intl,
}) => {
  const { userInfo } = useUserInfo()
  const [issueId, setIssueId] = useState(null)
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (issue && issue.id) {
      setIssueId(issue.id)
      setDescription(issue.description)
    } else if (fault && fault.id) {
      let desc = ''
      if (fault.reason) {
        desc += fault.reason
      }
      if (fault.component_description) {
        desc += ' - ' + fault.component_description
      } else if (fault.device_name) {
        desc += ' - ' + fault.device_name
      } else if (fault.description) {
        desc += ' - ' + fault.description
      }
      setDescription(desc)
      fetchNewIssueIdForProblemLog(
        fault.id,
        fault.component_id || '',
        fault.device_name || '',
        fault.harness || '',
        fault.short_code,
      ).then((issId) => {
        setIssueId(issId)
      })
    }
  }, [issue, fault])

  const updateIssueDesc = (desc) => {
    setDescription(desc)
    issue.description = desc
    setIssue(issue)
  }

  return (
    <div id="issue-summary">
      {!showList && (
        <div id="issue-preview">
          <span id="issue-id">{issueId}</span>
          &nbsp;:&nbsp;
          <span id="issue-description">
            <Input
              id="issue-description-input"
              value={description}
              onChange={(evt) => {
                updateIssueDesc(evt.target.value)
              }}
            />
          </span>
          <br />
          {!!issue.created_by && (
            <span id="issue-by">by {issue.created_by}</span>
          )}
          {userInfo && !issue.id && (
            <span id="issue-by">by {userInfo.username}</span>
          )}
        </div>
      )}
      {!showList && (
        <IssueEditForm
          orientation="vertical"
          issue={issue}
          setIssue={setIssue}
          fault={fault}
          readOnly={true}
          showFaultDetails={true}
        />
      )}
      {showList && (
        <IssueList
          fault={fault}
          issues={potentialIssues}
          setIssue={setIssue}
          setFault={setFault}
          intl={intl}
        />
      )}
    </div>
  )
}
