import axiosWrapper from './axios-wrapper'

// the endpoints for this are defined in
// src/controllers/issues.py

const api = axiosWrapper.create({
  baseURL: '/tracer/issues',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

// just GET issues/
// gets all of the issues
export function fetchIssues(userPlant) {
  const params = new URLSearchParams({ plants: [userPlant] })
  return api.get(`/?${params.toString()}`).then((resp) => resp.data)
}

// GET issues/?<params>
// gets potentially related issues using
// plant_id, component/harness/device id, fault_type, and dtc
export function fetchRelatedIssues(
  problem_log_id,
  fault_short_code,
  component_id,
  device_name,
  harness_id,
  userPlant,
) {
  const params = new URLSearchParams({
    problem_log_id,
    fault_short_code,
    component_id,
    device_name,
    harness_id,
    plants: [userPlant],
  })
  return api.get(`/?${params.toString()}`).then((resp) => resp.data)
}

// GET issues/?problem_log_id=<problem_log_id>
// gets issue linked to problem log
export function fetchAttachedIssue(problem_log_id) {
  const params = new URLSearchParams()
  params.set('problem_log_id', problem_log_id)
  params.set('find_attached', true)
  return api.get(`/?${params.toString()}`).then((resp) => resp.data)
}

// GET issues/<issue_id>
// gets a specific issue
export function fetchIssue(issue_id) {
  return api.get(`/${issue_id}`).then((resp) => resp.data)
}

// POST issues/
// creates a new issue.
/*
Issue should be like:
{
        'component': <id of component, harness, or device>,
        'fault_type': <type of fault>,
        'plant_id': <id of plant>,
        'dtc': <dtc>,
        'problem_description': {
                                    'what': <what>,
                                    'when': <when>,
                                    'where': <where>,
                                    'who': <who>,
                                    'which': <which>
                                },
        'possible_root_causes': {
                                    'man': <man>,
                                    'machine': <machine>,
                                    'method': <method>,
                                    'material': <material>,
                                    'design': <design>
                                },
        'direct_root_causes': {'five_whys': [<why1>, <why2>, ...]},
        'actions':  {
                        'assignee': <assignee>,
                        'description': <description>,
                        'start_date': <start_date>,
                        'completed_date': <completed_date>
                    },
        'status' (optional): 'open' or 'closed' (default open)
    }
the response should be something like:
{"issue_id": <issue_id>}
*/
export function postIssue(issue) {
  return api.post('/', issue).then((resp) => resp.data)
}

// PUT issue
// updates issue
// issue should be json similar to the post,
// but all fields are optional
export function updateIssue(issue_id, issue) {
  return api.put(`/${issue_id}`, issue).then((resp) => resp.data)
}

// DELETE issue
export function deleteIssue(issue_id) {
  return api.delete(`/${issue_id}`).then((resp) => resp.data)
}

// GET issue/<issue_id>/problem_logs
export function getIssueProblemLogs(issue_id) {
  return api.get(`/${issue_id}/problem_logs`).then((resp) => resp.data)
}

// POST issue/<issue_id>/problem_logs
export function linkProblemLogToIssue(issue_id, pl_id) {
  const payload = {
    problem_logs: [pl_id],
  }
  return api
    .post(`/${issue_id}/problem_logs`, payload)
    .then((resp) => resp.data)
}

export function fetchProblemLogsForIssues(issue_ids) {
  const params = new URLSearchParams()
  params.set('issue_ids', issue_ids.join(','))
  return api
    .get(`/problem_logs_for_issues?${params.toString()}`)
    .then((resp) => resp.data)
}

export function fetchNewIssueIdForProblemLog(
  problem_log_id,
  component_id,
  device_name,
  harness_id,
  fault_short_code,
) {
  const params = new URLSearchParams({
    problem_log_id,
    component_id,
    device_name,
    harness_id,
    fault_short_code,
  })
  return api.get(`/issue_id_for?${params.toString()}`).then((resp) => resp.data)
}
