import { useMemo } from 'react'
import Table from '../components/Table'
import dayjs from 'dayjs'

export const ProblemList = ({ problems }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'VIN',
        accessor: (row) => row.vin,
      },
      {
        Header: 'Timestamp',
        accessor: (row) => dayjs(row.created_at).format('MM/DD/YYYY h:mma'),
      },
      {
        Header: 'Notes',
        accessor: (row) => row.note,
      },
      {
        Header: 'UserID',
        accessor: (row) => row.created_by,
      },
    ],
    [problems],
  )
  return problems.length > 0 ? (
    <Table columns={columns} data={problems} id="problem-list" />
  ) : (
    ''
  )
}
