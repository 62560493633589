import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'
import IconDemo from '../Icons/IconDemo'
import IconReport from '../Icons/IconReport'
import IconDashboard from '../Icons/IconDashboard'
import IconFunctionalDiagrams from '../Icons/IconFunctionalDiagrams'
import IconSearch from '../Icons/IconSearch'
import IconVinSearch from '../Icons/IconVinSearch'
import IconUsers from '../Icons/IconUsers'
import IconComponentSearch from '../Icons/IconComponentSearch'
import IconDtcMemo from '../Icons/IconDtcMemo'
import IconProductionLine from '../Icons/IconProductionLine'
import { ROUTING, FUNCTIONAL_DIAGRAMS_FLAG } from '../../constants'
import DashboardCarousel from './DashboardCarousel'
import useWindowSize from '../../hooks/WindowSize'
import { useCheckPermission } from '../../hooks/Permissions'
import { useFeatureFlags } from '../../hooks/FeatureFlags'
import Loading from '../Loading'

// If you change this you have to change @media(max-width: 740px)
// in _main-dashboard.scss
const THREE_ITEMS_PER_PAGE_WIDTH = 740

function MainMenu() {
  const { isLoading, usersFeatureFlags } = useFeatureFlags()
  const [url, setUrl] = useState(null)
  const { width } = useWindowSize()
  const checkPermission = useCheckPermission()
  const wrapperClassNames = 'main-dashboard-button'

  if (isLoading) return <Loading />

  const elements = [
    checkPermission('page-gettingstarted:get') && (
      <button
        id="nav-to-getting-started"
        key="nav-to-getting-started"
        className={wrapperClassNames}
        onClick={() => setUrl('getting-started')}
        type="button"
      >
        <div className="main-dashboard-icon">
          <IconDashboard />
        </div>
        <div>{<FormattedMessage id="gettingStarted.routeLabel" />}</div>
      </button>
    ),
    checkPermission('page-report:get') && (
      <button
        id="nav-to-failure-report"
        key="nav-to-failure-report"
        className={wrapperClassNames}
        onClick={() => setUrl('/reports')}
        type="button"
      >
        <div className="main-dashboard-icon">
          <IconReport />
        </div>
        <div>{<FormattedMessage id="faultReport.routeLabel" />}</div>
      </button>
    ), //TODO: incident management permissions
    <button
      id="nav-to-incidents"
      key="nav-to-incidents"
      className={wrapperClassNames}
      onClick={() => setUrl('/incidents')}
      type="button"
    >
      <div className="main-dashboard-icon">
        <IconReport />
      </div>
      <div>Incident Management</div>
    </button>,
    checkPermission('page-search:get') && (
      <button
        key="nav-to-vehicle-search"
        className={wrapperClassNames}
        onClick={() => setUrl('/search')}
        id="nav-to-vehicle-search"
        type="button"
      >
        <div className="main-dashboard-icon">
          <IconVinSearch />
        </div>
        <div>{<FormattedMessage id="vinSearch.routeLabel" />}</div>
      </button>
    ),
    checkPermission('page-demo:get') && (
      <button
        key="nav-to-demo"
        className={wrapperClassNames}
        onClick={() => setUrl('/demo')}
        id="nav-to-demo"
        type="button"
      >
        <div className="main-dashboard-icon">
          <IconDemo />
        </div>
        <div>{<FormattedMessage id="demoTracer.routeLabel" />}</div>
      </button>
    ),
    usersFeatureFlags.includes(FUNCTIONAL_DIAGRAMS_FLAG) &&
      checkPermission('page-functional-diagrams:get') && (
        <button
          key="nav-to-functional-diagrams"
          className={wrapperClassNames}
          onClick={() => setUrl(ROUTING.functionalDiagrams)}
          id="nav-to-functional-diagrams"
          type="button"
        >
          <div className="main-dashboard-icon">
            <IconFunctionalDiagrams />
          </div>
          <div>{<FormattedMessage id="functionalDiagrams.routeLabel" />}</div>
        </button>
      ),
    checkPermission('page-component-search:get') && (
      <button
        key="nav-to-component-search"
        className={wrapperClassNames}
        onClick={() => setUrl('/global-search/configuration')}
        id="nav-to-component-search"
        type="button"
      >
        <div className="main-dashboard-icon">
          <IconSearch />
        </div>
        <div>{<FormattedMessage id="globalSearch.routeLabel" />}</div>
      </button>
    ),
    checkPermission('page-production-line-dashboard:get') && (
      <button
        key="nav-to-production-line-dashboard"
        className={wrapperClassNames}
        onClick={() => setUrl('/production-line-dashboard')}
        id="nav-to-production-line-dashboard"
        type="button"
      >
        <div className="main-dashboard-icon">
          <IconProductionLine />
        </div>
        <div>
          {<FormattedMessage id="productionLineDashboard.routeLabel" />}
        </div>
      </button>
    ),
    checkPermission('page-user-administration:get') && (
      <button
        key="nav-to-user-administration"
        className={wrapperClassNames}
        onClick={() => setUrl('/users')}
        id="nav-to-user-administration"
        type="button"
      >
        <div className="main-dashboard-icon">
          <IconUsers />
        </div>
        <div>{<FormattedMessage id="userAdministration.routeLabel" />}</div>
      </button>
    ),
    checkPermission('page-alerts:get') && (
      <button
        key="nav-to-alerts"
        className={wrapperClassNames}
        onClick={() => setUrl('/alerts')}
        id="nav-to-alerts"
        type="button"
      >
        <div className="main-dashboard-icon">
          <IconVinSearch />
        </div>
        <div>{<FormattedMessage id="alerts.routeLabel" />}</div>
      </button>
    ),
    checkPermission('manual-alerts:get') && (
      <button
        key="nav-to-manual-alerts"
        className={wrapperClassNames}
        onClick={() => setUrl('/manual-alerts')}
        id="nav-to-manual-alerts"
        type="button"
      >
        <div className="main-dashboard-icon">
          <IconDtcMemo />
        </div>
        <div>{<FormattedMessage id="manualAlerts.routeLabel" />}</div>
      </button>
    ),
    checkPermission('qrcode-scan:get') && (
      <button
        key="nav-to-qrcode-scan"
        className={wrapperClassNames}
        onClick={() => setUrl('/qrcode-scan')}
        id="nav-to-qrcode-scan"
        type="button"
      >
        <div className="main-dashboard-icon">
          <IconVinSearch />
        </div>
        <div>{<FormattedMessage id="qrCodeScan.routeLabel" />}</div>
      </button>
    ),
  ]

  if (url) {
    return <Redirect push to={url} />
  }

  // For larger screens we have 6 items per page,
  // for smaller vertical screens we have 3 items per page.
  const itemsPerPage = width <= THREE_ITEMS_PER_PAGE_WIDTH ? 3 : 6

  return <DashboardCarousel elements={elements} perPage={itemsPerPage} />
}

export default MainMenu
