import { useEffect, useState } from 'react'
import { Input } from 'antd'
import debounce from 'lodash/debounce'

const { Search } = Input

export const IssueSearch = ({
  issues,
  problemLogsForIssues,
  updateIssuesToShow,
  searchTerm,
  setSearchTerm,
}) => {
  // checks if any of the values in an object contain a term
  const objValsContainTerm = (obj, term) => {
    return Object.keys(obj).some((k) => {
      return (
        !!obj[k] &&
        typeof obj[k] === 'string' &&
        obj[k].toLowerCase().includes(term.toLowerCase())
      )
    })
  }

  // at least one of these must return true to appear in
  // issues to show
  const matchers = {
    iss: (issue, term) => {
      return objValsContainTerm(issue, term)
    },
    issueID: (issue, term) =>
      issue.id.toLowerCase().includes(term.toLowerCase()),
    problemDescription: (issue, term) => {
      const desc = issue.problem_description
      return objValsContainTerm(desc, term)
    },
    possibleRootCauses: (issue, term) => {
      const prc = issue.possible_root_causes
      return objValsContainTerm(prc, term)
    },
    directRootCauses: (issue, term) => {
      const drc = issue.direct_root_causes
      return (
        objValsContainTerm(drc, term) ||
        drc.five_whys.some((w) => w.includes(term))
      )
    },
    actions: (issue, term) => {
      const acts = issue.actions
      return acts.some((act) => {
        return objValsContainTerm(act, term)
      })
    },
    fault: (issue, term) => {
      const pls = problemLogsForIssues[issue.id]
      return pls.some((pl) => objValsContainTerm(pl, term))
    },
  }

  const issueMatches = (issue, term) => {
    return Object.keys(matchers).some((mk) => {
      return matchers[mk](issue, term)
    })
  }

  useEffect(() => {
    if (!issues) {
      return
    }
    if (searchTerm === '') {
      updateIssuesToShow(null)
      return
    }
    updateIssuesToShow(
      issues.filter((iss) => {
        return issueMatches(iss, searchTerm)
      }),
    )
  }, [searchTerm])

  const debouncedSetSearchTerm = debounce((evt) => {
    setSearchTerm(evt.target.value)
  }, 500)

  return (
    <Search
      onChange={debouncedSetSearchTerm}
      onSearch={setSearchTerm}
      placeholder="Search Issues"
    />
  )
}
