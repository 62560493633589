import { useEffect, useState } from 'react'
import { Input, Switch, Select } from 'antd'
import { useGetIssues } from '../hooks/Issues'

const emptyDirectRootCauses = {
  five_whys: [null, null, null, null, null],
  direct_root_cause: null,
  systemic_root_cause: null,
  detection_root_cause: null,
}

const getDirectRootCauses = (issue) => {
  if (!!issue && !!issue.direct_root_causes) {
    return issue.direct_root_causes
  }
  return structuredClone(emptyDirectRootCauses)
}

// This is the "actual identified root cause(s)" component
export const DirectRootCauses = ({ issue, setIssue, readOnly }) => {
  const [directRootCauses, setDirectRootCauses] = useState(
    getDirectRootCauses(issue),
  )

  useEffect(() => {
    setDirectRootCauses(getDirectRootCauses(issue))
  }, [issue])

  const [systemicEnabled, setSystemicEnabled] = useState(false)
  useEffect(() => {
    if (!!directRootCauses) {
      setSystemicEnabled(!!directRootCauses.systemic_root_cause)
    }
  }, [directRootCauses])

  const { issues } = useGetIssues()
  const [systemicOpts, setSystemicOpts] = useState([])
  useEffect(() => {
    if (!issues) {
      return
    }
    const opts = []
    issues.forEach((iss) => {
      const rootCauses = iss.direct_root_causes
      if (!!rootCauses.systemic_root_cause) {
        opts.push(rootCauses.systemic_root_cause)
      }
    })
    const allOpts = [...new Set(opts)]
    setSystemicOpts(
      allOpts
        .filter((opt) => !!opt)
        .map((opt) => {
          return {
            value: opt,
            label: opt,
          }
        }),
    )
  }, [issues])

  const updateIssueDirectRootCauses = (key, evt) => {
    const value = evt.target.value
    directRootCauses[key] = value
    setDirectRootCauses(directRootCauses)
    let newIssue = { ...issue, ...{ direct_root_causes: directRootCauses } }
    setIssue(newIssue)
  }

  const updateSystemicRootCause = (value) => {
    directRootCauses['systemic_root_cause'] = value[0]
    setDirectRootCauses(directRootCauses)
    let newIssue = { ...issue, ...{ direct_root_causes: directRootCauses } }
    setIssue(newIssue)
  }

  const updateFiveWhys = (i, evt) => {
    const value = evt.target.value
    directRootCauses['five_whys'][i] = value
    setDirectRootCauses(directRootCauses)
    let newIssue = { ...issue, ...{ direct_root_causes: directRootCauses } }
    setIssue(newIssue)
  }

  const getWhyNum = (i) => {
    if (directRootCauses && directRootCauses['five_whys'].length > i) {
      return directRootCauses['five_whys'][i]
    }
    return null
  }

  const getFiveWhysInputs = () => {
    const inputs = []
    for (let i = 0; i < 5; i++) {
      inputs.push(
        <div className="input-and-label" key={`direct-root-causes-why-${i}`}>
          <span className="input-label">Why {i + 1}:</span>
          <Input
            placeholder={readOnly ? '' : 'Enter'}
            onChange={(evt) => updateFiveWhys(i, evt)}
            value={getWhyNum(i)}
            disabled={readOnly}
          />
        </div>,
      )
    }
    return inputs
  }

  return (
    <div id="issue-direct-root-causes">
      {getFiveWhysInputs()}
      <div className="input-and-label" key={`direct-root-cause`}>
        <span className="input-label">Direct Root Cause:</span>
        <Input
          placeholder={readOnly ? '' : 'Enter'}
          onChange={(evt) =>
            updateIssueDirectRootCauses('direct_root_cause', evt)
          }
          value={directRootCauses['direct_root_cause']}
          disabled={readOnly}
        />
      </div>
      <div className="input-and-label" key={`detection-root-cause`}>
        <span className="input-label">Detection Root Cause:</span>
        <Input
          placeholder={readOnly ? '' : 'Enter'}
          onChange={(evt) =>
            updateIssueDirectRootCauses('detection_root_cause', evt)
          }
          value={directRootCauses['detection_root_cause']}
          disabled={readOnly}
        />
      </div>
      <div
        className="input-and-label systemic-input"
        key={`systemic-root-cause`}
      >
        <span className="input-label">Systemic Root Cause:</span>
        <Switch
          onClick={() => setSystemicEnabled(!systemicEnabled)}
          defaultChecked={systemicEnabled}
          disabled={readOnly}
        />
        <Select
          mode="tags"
          placeholder={readOnly ? '' : 'Enter or select'}
          value={
            !!directRootCauses['systemic_root_cause']
              ? [directRootCauses['systemic_root_cause']]
              : []
          }
          disabled={readOnly || !systemicEnabled}
          options={systemicOpts}
          onChange={(value) => updateSystemicRootCause(value)}
          maxCount="1"
        />
      </div>
    </div>
  )
}
