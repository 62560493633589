import { useQuery } from 'react-query'
import { apiFetchDTCsForProblemLog, apiSearchDTCs } from '../api/dtcs-api'

export const DTC_KEY = 'dtc'

export const useSearchDTCs = ({ searchString, makeModelID }) => {
  const {
    status,
    data: dtcs,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [DTC_KEY, makeModelID, searchString],
    async () => {
      const result = await apiSearchDTCs({ searchString, makeModelID })
      return result.data
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!makeModelID && !!searchString,
    },
  )
  return {
    status,
    dtcs,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const useSearchDTCsByProblemLog = (problem_log_id) => {
  const {
    status,
    data: dtcs,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [DTC_KEY, problem_log_id],
    async () => {
      const result = await apiFetchDTCsForProblemLog(problem_log_id)
      return result
    },
    {
      refetchOnWindowFocus: true,
    },
  )
  return {
    status,
    dtcs,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
